.footer {
  z-index: 999;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: var(--header-width);
  padding: 15px 0px;
  border-top: 1px solid #454258;
  background: #000;
  .wrapWidth {
    .tag {
      color: #fff;
      font-size: 12px;
      font-weight: 600;
      font-family: "Inter";
      margin-bottom: 10px;
      @media only screen and (max-width: 480px) {
        text-align: center;
      }
    }
    .desc {
      font-size: 14px;
      color: #fff;
      font-weight: 500;
      font-family: "Inter";
      @media only screen and (max-width: 480px) {
        text-align: center;
      }
    }
  }
}
.icon {
  height: 32px;
  width: 32px;
  padding: 6px;
  padding-right: 0px;
  border-radius: 3px;
  // background: rgb(111, 111, 111);
  cursor: pointer;
  svg {
    height: 20px;
    width: 20px;
  }
  &:hover {
    background: #fff;
    svg {
      path {
        fill: rgb(0, 0, 0);
      }
    }
  }
}
