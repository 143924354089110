@import "./media.scss";

.wrapperWidth {
  width: 1200px;
  margin: 0 auto;
  @include media("<=xlarge") {
    width: 1200px;
    margin: 0 auto;
  }
  @include media("<=large") {
    width: 100%;
    padding: 0px 3%;
  }
  @include media("<=desktop") {
    width: 100%;
    padding: 0px 3%;
  }
  @include media("<=tablet") {
    width: 100%;
    padding: 0px 3%;
  }
  @include media(">=phone", "<=480px") {
    padding: 0px 5%;
  }
}

.bg-overlay {
  .bg-video {
  }
}
.page_block {
  background: rgba(0, 0, 0, 0.8);
}
.home-page {
  min-height: 730px;
  padding: 40px 0;

  .wrapper {
    @include media("<=tablet") {
      gap: 40px;
      flex-direction: column;
    }
    .left-box {
      @include media("<=tablet") {
        flex: 1;
        width: 100%;
      }
      .flexbox-fix {
        &:nth-child(1) {
          display: none !important;
        }
      }

      .btn-show {
        width: 60%;
        display: none;
        color: #fff;
        cursor: pointer;
        border-radius: 0.25rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        padding: 0.375rem 0.75rem;
        text-align: center;
        border: 1px solid #fff;
        @include media("<=phoneH") {
          display: flex;
        }
      }
      .color-piker-box {
        @include media("<=phoneH") {
          display: none;
        }
        &.show {
          display: flex;
        }
        .rcp {
          height: 100% !important;
        }
      }
    }
    .center-box {
      @include media("<=tablet") {
        flex: 1;
        width: 100%;
      }
      .color-preview-box {
        background: #fff;
        border-radius: 4px;
        padding: 26px;
        height: 500px;
        width: 400px;
        @include media("<=tablet") {
          padding: 26px;
        }

        .boxtext {
          width: 100%;
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 17px;
        }
        .boxmaintext {
          font-weight: 700;
          font-family: "Inter";
          font-style: normal;
          font-size: 17px;
          text-transform: uppercase;
        }
      }
    }
    .right-box {
      @include media("<=tablet") {
        flex: 1;
        width: 100%;
      }
      .input-field {
        .field-lbl {
        }
        .field-txt {
          border-bottom: 2px solid #9b9b9b;
        }
        .text-area {
          border: 2px solid #9b9b9b;
        }
      }
    }
  }
}

.success-page {
  .color-preview-box {
    background: #fff;
    border-radius: 4px;
    padding: 26px;
    min-height: 400px;
    width: 400px;
    @include media("<=tablet") {
      padding: 26px;
    }

    .boxtext {
      width: 100%;
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 17px;
    }
    .boxmaintext {
      font-weight: 700;
      font-family: "Inter";
      font-style: normal;
      font-size: 17px;
      text-transform: uppercase;
    }
  }
}
