.font {
  font-family: "Inter";
}
body {
  font-family: "Inter";
  margin: 0px;
  padding: 0px;
}
.flex {
  display: flex;
}
.aic {
  align-items: center;
}
.jc {
  justify-content: center;
}
.flex-col {
  flex-direction: column;
}
.col {
  flex-direction: column;
}

/* dropdownAnim */
.dropDownAnim {
  animation-name: drop;
  animation-duration: 0.3s;
  transform-origin: top;
}

:root {
  --header-width: 85px;
}

/* html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
} */
.wrapWidth {
  width: 1140px;
  margin: 0 auto;
}

.mainbody {
  width: 100%;
  /* min-height: 100vh; */
  background-image: linear-gradient(
    to top right,
    darkblue,
    black,
    black,
    purple
  );
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* position: relative; */
}

.body-content {
  height: 100%;
}

.home {
  flex: 1;
  width: 100%;
  height: 100%;
  /* padding-top: 70px; */
  z-index: 2;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.home-wrap {
  height: 100%;
}
.home .home-l {
  flex: 1;
  align-items: start;
}
.home .home-r {
  flex: 1;
  height: 100%;
  justify-content: end;
}
.home .home-r .input-r {
  font-family: "Inter";
  color: white;
  width: 100%;
  margin-bottom: 20px;
}
.home .home-r .input-r .lbl {
  margin-bottom: 10px;
}
.home .home-r .txt {
  font-size: 16px;
  color: white;
  font-weight: 500;
}
.home .home-r .mint {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
}
.home .home-r .rightbox {
  padding: 12px;
  line-height: 18px;
}
.home .home-r .btn {
  color: white;
  margin-bottom: 40px;
  width: 100% !important;
}
.home .home-r .input-r .lbl2 {
}
.home .home-r .input-r .check-box {
  height: 18px;
  width: 18px;
}
.mail-form {
  width: 100%;
  gap: 30px;
  margin: 12px 0;
}

.overlay {
  background: rgba(0, 0, 0, 0.8);
  z-index: 0;
  /* position: fixed; */
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.show-color {
  display: none;
}
.show-color .btn {
  color: #fff;
  cursor: pointer;
  border: 1px solid #fff;
  font-family: "Inter";
}

.bg-img {
  z-index: 0;
  width: 100%;
  min-height: calc(115vh - 37px);
  object-fit: cover;
  background-color: #fff;
}

.home .home-r .input-r .lbl {
  font-size: 18px;
  font-weight: 600 !important;
}

.flexbox-fix {
  /* display: none !important; */
}
.rcp-fields-element {
  flex-direction: row !important;
}

.manue {
  /* background: #fff; */
  z-index: 20;
  width: 180px;
  position: absolute;
  top: 65px;
  right: 12px;
  /* padding: 10px 0px; */
  box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 16px;
  border-radius: 4px;
}
.manue.show {
  animation-name: drop;
  animation-duration: 0.3s;
  transform-origin: top;
}
.manue .tag {
  color: #121212;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 20px;
}
